import * as React from "react"
import BaseLayout from "../../components/layout"
import Seo from "../../components/seo";
import { graphql } from "gatsby";
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";


export default class TheAssortedNapsOfSkutullThoutt extends React.Component {

  render() {
    const skutullImageNums = [...Array(25).keys()].map(i => i + 1);

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'The Assorted Naps of Skutull Thoutt',
        }
      ]
    ]

    return (
      <BaseLayout>
        <Seo title="The Assorted Naps of Skutull Thoutt" pathname={this.props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint mb-28">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">The Assorted Naps of Skutull Thoutt</h1>
          </div>

          <div className="flex flex-wrap justify-start">
            { skutullImageNums.map((num, index) => (
              <img className="block" key={num} style={{height: '250px', width: 'auto !important'}} src={`https://media.zackthoutt.com/img/skutull-naps/skutull-nap-${num}.jpeg`} alt="Sktull napping."/>
            ))}

          </div>
        </div>
      </BaseLayout>
    )
  }

}

export const query = graphql`
  query SkutullNapsQuery {
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
  }
`
